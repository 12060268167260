




























import { Component, Vue } from 'vue-property-decorator';
import { JobAdvertisementContent } from '@/types/neuhaus-website/cms/pages/job-advertisement';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageJobAdvertisement Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-card': () => import('@/components/cms/components/CMSComponentCard.vue'),
        'cms-component-unordered-list': () => import('@/components/cms/components/CMSComponentUnorderedList.vue') 
    }
})
export default class CMSPageJobAdvertisement extends Vue {
    // variable with page content
    private pageContent: JobAdvertisementContent = {
        id: -1,
        title: 'N/A',
        jobAdvertisements: [],
        noJobAdvertisementsText: 'N/A'
    };

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns JobAdvertisementContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<JobAdvertisementContent> {
        try {
            const response = await axios.get<APIResponse<JobAdvertisementContent>>('/cms/pages/jobAdvertisement')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }
}
